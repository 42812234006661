import React from 'react'
import { IPage } from '../../../types/prismic/pages/generalPage'
import {
	IBaseSlice,
} from '../../../types/prismic/baseTypes'
import { Cell, Container, GridContainer, Label, Text } from './style'
import { useRouter } from 'next/router'
import KvikaArrow from '../../icons/KvikaArrow'
import { usePrismicRoutingContext } from '../../../utils/prismic/Context'
import { resolvePrismicLink } from '../../../utils/prismic/RouteHelpers'
import { IPrismicLink } from '../../../types/prismic/baseTypes'

export interface ILinkListDescriptionColumns extends IBaseSlice {
	primary: {
		section_color?: IPage.Section['section_color']
	}
	items: {
		description1: string
		title1: string
		link: IPrismicLink
	}[]
	slice_type: 'link_list_description_columns'
}

interface Props {
	sliceData: ILinkListDescriptionColumns
}

const LinkListDescriptionColumns = ({ sliceData }: Props) => {
	const router = useRouter()
	const context = usePrismicRoutingContext();

	const handleClick = (link: IPrismicLink) => {
		const url = resolvePrismicLink(link, context.pages);
		url && router.push(url);
	}

	return (
		<GridContainer>
			<Container>
				{sliceData.items.map((item, i) => {
					return (
						<Cell key={i} onClick={() => handleClick(item.link)}>
							<Label>{item.title1} <KvikaArrow width={50}/> </Label>
							<Text>{item.description1}</Text>
						</Cell>
					)
				})}
			</Container>
		</GridContainer>
	)
}

export default LinkListDescriptionColumns
