import styled, { keyframes } from 'styled-components'
import { FullBleedStyles } from '../../Layout/Grid/Grid'
import { theme } from '../../../styles/globalStyles'
import { media } from '../../../utils/style-utils'

const opacityAnim = keyframes`
    from {
        opacity:  0;
    }

    to {
        opacity: 100;
    }
`

const opacityAnimOut = keyframes`
    from {
        opacity:  100;
    }

    to {
        opacity: 0;
    }
`

const borderAnim = keyframes`
    from {
        border-bottom: solid 1px ${theme.colors.gold150};
    }

    to {
        border-bottom: solid 1px ${theme.colors.gold400};
    }
`

const borderAnimOut = keyframes`
    from {
        border-bottom: solid 1px ${theme.colors.gold400};
    }

    to {
        border-bottom: solid 1px ${theme.colors.gold150};
    }
`

export const GridContainer = styled.div`
	${FullBleedStyles};
	background-color: ${theme.colors.grey50};
`

export const Container = styled.div`
	grid-column: 2/-2;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 104px;
	row-gap: 56px;
	font-family: AdobeCaslonPro;
	padding: 120px 0;

    ${media.phone`
	    grid-template-columns: 1fr;
		padding: 40px 0;
    `}
`

export const Cell = styled.div`
	display: flex;
	flex-direction: column;
	height: 140px;
	border-bottom: solid 1px ${theme.colors.gold150};
	cursor: pointer;
	animation: ${borderAnimOut} 0.4s ease-in;

	${media.phone`
		padding: 14px 0;
	`}

	& > div > svg  {
		${media.phone`
				display: none;
			`
		}
		animation: ${opacityAnimOut} 0.4s ease-in;
		animation-fill-mode: both;

		path {
			fill: ${theme.colors.gold400};
		}
	}

	&:hover {
		animation: ${borderAnim} 0.4s ease-in;
		animation-fill-mode: forwards;

		div > svg {
			animation: ${opacityAnim} 0.4s ease-in;
			animation-fill-mode: forwards;

			path {
				fill: ${theme.colors.gold400};
			}
		}
	}
`

export const Label = styled.div`
	font-size: 28px;
	line-height: 42px;
	color: ${theme.colors.grey800};

	svg {
		opacity: 0;
	}
`

export const Text = styled.div`
	font-size: 18px;
	line-height: 32.4px;
	color: ${theme.colors.grey800};
`
